// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"; // Import for Firebase Realtime Database
import { getAuth } from "firebase/auth"; // Import for FirebaseAuth

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkuzk2m51WsK8IKBQujNntN95ASwzMW1o",
  authDomain: "falcon-ride-ee3fb.firebaseapp.com",
  databaseURL: "https://falcon-ride-ee3fb-default-rtdb.firebaseio.com",
  projectId: "falcon-ride-ee3fb",
  storageBucket: "falcon-ride-ee3fb.appspot.com",
  messagingSenderId: "19811799734",
  appId: "1:19811799734:web:4f4209d672638dbb620c17",
  measurementId: "G-44LE8YNMMD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app); // Initialize Firebase Realtime Database
const auth = getAuth(app); // Initialize FirebaseAuth
