import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import './LoginPage.css';
import { Link } from "react-router-dom";

function LoginPage({ setLoggedIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth();

  const loginUser = (e) => {
    e.preventDefault(); // Prevents the form from refreshing the page
    if (!email || !password) {
      setError("Email or password is empty.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Logged in
        setLoggedIn(true);
        console.log("Login successful.");
      })
      .catch((error) => {
        setError("Incorrect email or password.");
        console.error("Login error:", error.message);
      });
  };

  return (
    <div className="login-container">
      <form onSubmit={loginUser} className="login-form">
        <h2>Log In</h2>
        {error && <p className="error">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Log In</button>
        <Link to="/signup">Don't have an account? Sign Up</Link>
        <br />
        <Link to="/saferidesignup" style={{marginTop: '10px'}}>Volunteer to be a SafeRide driver</Link> {/* New Link for SafeRide Signup */}
      </form>
    </div>
  );
}

export default LoginPage;
