import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue, remove, get, set } from 'firebase/database'; // Import get function
import { useNavigate } from 'react-router-dom';
import './Profile.css';

function Profile() {
  const [userData, setUserData] = useState({ name: 'Loading...', username: 'Loading...', number: 'Loading...' });
  const [postedRides, setPostedRides] = useState([]);
  const [postedRequests, setPostedRequests] = useState([]);
  const [bookings, setBookings] = useState([]);

  const navigate = useNavigate();
  const db = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
        return;
      }
      const userID = user.uid;
      fetchUserData(userID);
      fetchItems(`rideReserve`, setPostedRides, userID);
      fetchItems(`rideRequest`, setPostedRequests, userID);
      fetchBookingsDetails(userID);
    });
  }, [auth, db, navigate]);

  const fetchUserData = (userID) => {
    const userRef = ref(db, `users/${userID}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData({
          name: data.name || 'Unknown',
          username: data.username || 'Unknown',
          number: data.number || 'Unknown',
        });
      }
    });
  };

  const fetchItems = (path, setState, userID) => {
    const itemsRef = ref(db, path);
    onValue(itemsRef, (snapshot) => {
      const items = [];
      snapshot.forEach((childSnapshot) => {
        const item = childSnapshot.val();
        if (item.userID === userID) {
          items.push({ ...item, id: childSnapshot.key });
        }
      });
      setState(items);
    });
  };

  const fetchBookingsDetails = async (userID) => {
    const bookingsRef = ref(db, 'bookings');
    const snapshot = await get(bookingsRef).catch(error => console.error("Error fetching bookings:", error));
    if (snapshot) {
      const allBookings = [];
      snapshot.forEach((childSnapshot) => {
        const booking = childSnapshot.val();
        if (booking.bookerUserID === userID || booking.providerUserID === userID) {
          allBookings.push(fetchDetailsForBooking(childSnapshot.key, booking, userID));
        }
      });
      const bookingDetails = await Promise.all(allBookings);
      setBookings(bookingDetails.filter(detail => detail !== null)); // Filter out any nulls
    }
  };

  const fetchDetailsForBooking = async (bookingId, booking, userID) => {
    const detailRef = ref(db, `${booking.type === 'reservation' ? 'rideReserve' : 'rideRequest'}/${booking.rideID}`);
    try {
      const snapshot = await get(detailRef);
      if (!snapshot.exists()) {
        console.error("No data found for ride details:", booking.rideID);
        return null;
      }

      const details = snapshot.val();
      if (!details) {
        return null;
      }

      // Determine the other party's user ID (either the booker or the provider)
      const otherUserID = booking.bookerUserID === userID ? booking.providerUserID : booking.bookerUserID;

      // Fetch the other party's contact information
      const userContact = await fetchUserContact(otherUserID);

      // Determine the booking type for display
      const bookingTypeDisplay = booking.type === 'reservation' ? 'Booked Ride' : 'Accepted Request';

      // Merge booking info with ride details and include userContact and bookingTypeDisplay
      return { ...booking, id: bookingId, ...details, userContact, isProvider: details.userID === userID, bookingType: bookingTypeDisplay };
    } catch (error) {
      console.error("Error fetching ride details:", error);
      return null;
    }
  };

  // Helper function to fetch user contact information
  const fetchUserContact = async (userID) => {
    const userRef = ref(db, `users/${userID}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const userData = snapshot.val();
      return userData.number; // Assuming 'number' is the field for user contact information
    }
    return 'N/A'; // Return 'N/A' if user data or contact number is not found
  };

  const handleDelete = (path, id) => {
    const shouldDelete = window.confirm("Are you sure you want to delete this item?");
    if (!shouldDelete) {
      return; // User canceled deletion
    }
  
    const itemRef = ref(db, `${path}/${id}`);
    remove(itemRef)
      .then(() => {
        console.log("Item deleted successfully.");
        // Refresh the items list after deletion
        if (path === 'rideReserve') {
          fetchItems(`rideReserve`, setPostedRides, auth.currentUser.uid);
        } else if (path === 'rideRequest') {
          fetchItems(`rideRequest`, setPostedRequests, auth.currentUser.uid);
        }
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };
  
  const cancelBooking = async (bookingId, seatsToCancel) => {
    // Define the booking reference
    const bookingRef = ref(db, `bookings/${bookingId}`);
    try {
      // Use get() for a one-time fetch
      const bookingSnapshot = await get(bookingRef);
      if (!bookingSnapshot.exists()) {
        console.error("Booking not found");
        return;
      }
      const bookingData = bookingSnapshot.val();
      const { rideID, type } = bookingData;
      const rideRefPath = type === 'reservation' ? 'rideReserve' : 'rideRequest';
      const rideRef = ref(db, `${rideRefPath}/${rideID}`);
  
      // Fetch ride data
      const rideSnapshot = await get(rideRef);
      if (!rideSnapshot.exists()) {
        console.error("Ride not found");
        return;
      }
      const rideData = rideSnapshot.val();
      const currentSeats = parseInt(rideData.seats || 0);
      const updatedSeats = currentSeats + seatsToCancel;
  
      // Update the ride with the new number of seats
      await set(rideRef, { ...rideData, seats: updatedSeats.toString() });
  
      // Delete the booking
      await remove(bookingRef);
      console.log("Booking canceled and seats updated successfully.");
  
      // Refresh data to reflect changes
      fetchBookingsDetails(auth.currentUser.uid);
      fetchItems(rideRefPath, rideRefPath === 'rideReserve' ? setPostedRides : setPostedRequests, auth.currentUser.uid);
    } catch (error) {
      console.error("Error in canceling booking:", error);
    }
  };
  

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>{userData.name}</h1>
        <p>@{userData.username}</p>
        <p>{userData.number}</p>
        <button onClick={() => navigate('/settings')}>Settings</button>
      </div>
      <div className="profile-content">
        <Section title="Posted Rides" items={postedRides} onDelete={(id) => handleDelete('rideReserve', id)} />
        <Section title="Posted Requests" items={postedRequests} onDelete={(id) => handleDelete('rideRequest', id)} />
        <Section title="Bookings" items={bookings} detailed={true} onCancelBooking={(bookingId, seatsToCancel) => cancelBooking(bookingId, seatsToCancel)} />
      </div>
    </div>
  );
}

function Section({ title, items, onDelete, onCancelBooking, detailed = false }) {
  return (
    <div className="section">
      <h2>{title}</h2>
      <div className="items">
        {items.map((item) => (
          <div key={item.id} className="item-card">
            <p><strong>From:</strong> {item.fromLocation || 'N/A'} <strong>To:</strong> {item.toLocation || 'N/A'}</p>
            <p><strong>Date:</strong> {(item.date && new Date(item.date).toLocaleDateString()) || 'N/A'} <strong>Time:</strong> {item.time || 'N/A'}</p>
            {detailed && <p><strong>Contact:</strong> {item.userContact || (item.isProvider ? 'Your Contact' : 'N/A')}</p>}
            <p><strong>Seats:</strong> {item.seats || 'N/A'} <strong>Donation:</strong> {item.donationRequested || 'N/A'}</p>
            {item.bookingType && <p><strong>Type:</strong> {item.bookingType}</p>}
            <p>{item.additionalInfo || 'No additional info'}</p>
            {onDelete && (
              <button className="delete-button" onClick={() => onDelete(item.id)}>Delete</button>
            )}
            {onCancelBooking && (
              <button className="cancel-button" onClick={() => {
                const seatsToCancel = parseInt(prompt("Enter the number of seats to cancel:"));
                if (!isNaN(seatsToCancel) && seatsToCancel > 0) {
                  onCancelBooking(item.id, seatsToCancel);
                } else {
                  alert("Please enter a valid number of seats.");
                }
              }}>Cancel</button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Profile;

