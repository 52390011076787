import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import './SafeRideSignUp.css'; // Ensure you have the CSS for styling

function SafeRideSignUp({ setLoggedIn }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (authCode !== "NAIJA") {
      setError("Invalid authentication code.");
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      const db = getDatabase();
      set(ref(db, 'safeusers/' + userId), {
        personalInfo: {
          firstName,
          lastName,
          email,
          phoneNumber
        },
        stats: {
          totalHoursDriven: 0,
          totalMilesDriven: 0
        },
        saferides: {} // Initialize empty or you can omit this line until the first ride is added.
      });
      setLoggedIn(true);
    } catch (error) {
      setError(error.message);
    }
  };
  

  return (
    <div className="sign-up-container">
      <form onSubmit={handleSignUp} className="sign-up-form">
        <h2>DM @Usafa_saferide on instagram to volunteer!</h2>
        {error && <p className="error">{error}</p>}
        <input type="text" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
        <input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
        <input type="email" placeholder="USAF Academy Email" value={email} onChange={e => setEmail(e.target.value)} />
        <input type="tel" placeholder="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
        <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        <input type="text" placeholder="Authentication Code" value={authCode} onChange={e => setAuthCode(e.target.value)} />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default SafeRideSignUp;
