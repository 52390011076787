import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, push, update, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './AddSafeRide.css';

function AddSafeRide() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [date, setDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [seats, setSeats] = useState('');
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', phoneNumber: '' });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        const db = getDatabase();
        const userRef = ref(db, `safeusers/${user.uid}/personalInfo`);
        onValue(userRef, (snapshot) => {
          setUserInfo(snapshot.val() || {});
        });
      }
    });

    return unsubscribe;
  }, [auth]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!currentUser) {
      console.error('No user signed in!');
      return;
    }

    const db = getDatabase();
    const newShiftRef = push(ref(db, `safeusers/${currentUser.uid}/saferides`));
    update(newShiftRef, {
      date,
      fromTime,
      toTime,
      seats,
      driverName: `${userInfo.firstName} ${userInfo.lastName}`,
      contact: userInfo.phoneNumber,
    })
      .then(() => {
        console.log('Shift added successfully!');
        navigate('/saferide'); // Redirect back to the SafeRide page
      })
      .catch((error) => {
        console.error('Failed to add shift:', error);
      });
  };

  return (
    <div className="addsaferide-container">
      <h1>Add Shift</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Date:</label>
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
        </div>
        <div>
          <label>From Time:</label>
          <input type="time" value={fromTime} onChange={(e) => setFromTime(e.target.value)} required />
        </div>
        <div>
          <label>To Time:</label>
          <input type="time" value={toTime} onChange={(e) => setToTime(e.target.value)} required />
        </div>
        <div>
          <label>Seats Available:</label>
          <input type="number" value={seats} onChange={(e) => setSeats(e.target.value)} min="1" required />
        </div>
        <div>
          <h3>Driver Information:</h3>
          <p>Name: {userInfo.firstName} {userInfo.lastName}</p>
          <p>Contact: {userInfo.phoneNumber}</p>
        </div>
        <button type="submit">Add Shift</button>
      </form>
    </div>
  );
}

export default AddSafeRide;
