import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom'; // For navigation after adding a ride
import './AddRequest.css'; // Ensure to create and style your form accordingly

function AddRequest() {
  const [fromLocation, setFromLocation] = useState('');
  const [toLocation, setToLocation] = useState('');
  const [seats, setSeats] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [donationRequested, setDonationRequested] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const navigate = useNavigate();

  const postRide = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getDatabase();
    const userID = auth.currentUser ? auth.currentUser.uid : null;

    if (!userID) {
      alert("User not logged in.");
      return;
    }

    setIsPosting(true);

    const newRideRef = ref(db, 'rideRequest');
    push(newRideRef, {
      userID,
      fromLocation,
      toLocation,
      seats,
      date: selectedDate + ":00.000Z", // Append ":00.000Z" to the date string
      time: formatTime(selectedDate), // Format time according to specified format
      donationRequested,
      additionalInfo
    })
    .then(() => {
      alert("Ride successfully added!");
      navigate('/Request'); // Navigate back to the Request page or wherever appropriate
    })
    .catch((error) => {
      alert(`Error posting ride: ${error.message}`);
    })
    .finally(() => {
      setIsPosting(false);
    });
  };

  // Function to format time according to specified format
  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure minutes are always two digits
    const time = `${hours}:${minutes}`;
    return time;
  };

  return (
    <div className="add-request-container">
      <form onSubmit={postRide} className="add-request-form">
        <h2>Add Ride</h2>
        {/* Form fields for ride details */}
        <input type="text" placeholder="From" value={fromLocation} onChange={(e) => setFromLocation(e.target.value)} required />
        <input type="text" placeholder="To" value={toLocation} onChange={(e) => setToLocation(e.target.value)} required />
        <input type="number" placeholder="Seats Available" value={seats} onChange={(e) => setSeats(e.target.value)} required />
        <input type="datetime-local" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} required />
        <input type="text" placeholder="Donation Requested" value={donationRequested} onChange={(e) => setDonationRequested(e.target.value)} />
        <textarea placeholder="Additional Info" value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)}></textarea>
        <button type="submit" disabled={isPosting}>{isPosting ? 'Posting...' : 'Add Ride'}</button>
      </form>
    </div>
  );
}

export default AddRequest;
