import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import './App.css';
import LoginPage from './Account/LoginPage';
import SignUp from './Account/SignUp';
import Reserve from './Reserve/Reserve';
import Request from './Request/Request';
import Activity from './Activity/Activity';
import Profile from './Profile/Profile';
import Settings from './Settings/Settings';
import AddReserve from './Reserve/AddReserve';
import AddRequest from './Request/AddRequest';
import SafeRide from './SafeRide/SafeRide';
import AddSafeRide from './SafeRide/AddSafeRide';
import SafeRideSignUp from './Account/SafeRideSignUp'; // Import SafeRideSignUp component
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import './firebase-config';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSafeUser, setIsSafeUser] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const db = getDatabase();
        const safeUserRef = ref(db, `safeusers/${user.uid}`);
        onValue(safeUserRef, (snapshot) => {
          if (snapshot.val()) {
            setIsSafeUser(true);
          } else {
            setIsSafeUser(false);
          }
        });
      } else {
        setIsLoggedIn(false);
        setIsSafeUser(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate replace to={isSafeUser ? '/saferide' : '/saferide'} /> : <LoginPage setLoggedIn={setIsLoggedIn} />} />
          <Route path="/signup" element={isLoggedIn ? <Navigate replace to={isSafeUser ? '/saferide' : '/saferide'} /> : <SignUp setLoggedIn={setIsLoggedIn} />} />
          <Route path="/saferidesignup" element={<SafeRideSignUp setLoggedIn={setIsLoggedIn} />} /> {/* Add route for SafeRideSignUp */}
          {isLoggedIn ? (
            <>
              {!isSafeUser && (
                <>
                  <Route path="/reserve" element={<Reserve />} />
                  <Route path="/request" element={<Request />} />
                  <Route path="/activity" element={<Activity />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/addreserve" element={<AddReserve />} />
                  <Route path="/addrequest" element={<AddRequest />} />
                </>
              )}
              <Route path="/saferide" element={<SafeRide isSafeUser={isSafeUser} />} />
              {isSafeUser && <Route path="/addsaferide" element={<AddSafeRide />} />}
            </>
          ) : (
            <Route path="*" element={<Navigate replace to="/" />} />
          )}
        </Routes>
        {isLoggedIn && (
          <nav className="bottom-tab-bar">
            {!isSafeUser && (
              <>
                <NavLink
                  to="/reserve"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  Reserve
                </NavLink>
                <NavLink
                  to="/request"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  Request
                </NavLink>
                <NavLink
                  to="/activity"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  Activity
                </NavLink>
                <NavLink
                  to="/profile"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  Profile
                </NavLink>
              </>
            )}
            <NavLink
              to="/saferide"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              SafeRide
            </NavLink>
            {isSafeUser && (
              <NavLink
                to="/addsaferide"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Add SafeRide
              </NavLink>
            )}
          </nav>
        )}
      </div>
    </Router>
  );
}

export default App;
