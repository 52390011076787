import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import './SafeRide.css';

function SafeRide({ isSafeUser }) {
  const [rides, setRides] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const db = getDatabase();
    const ridesRef = ref(db, 'safeusers');
    onValue(ridesRef, (snapshot) => {
      const ridesData = [];
      const today = new Date().setHours(0, 0, 0, 0);

      snapshot.forEach((childSnapshot) => {
        const personalInfo = childSnapshot.child('personalInfo').val();
        const saferides = childSnapshot.child('saferides').val();

        if (saferides) {
          Object.keys(saferides).forEach((rideId) => {
            const ride = saferides[rideId];
            const rideDate = new Date(ride.date);

            // Only add future rides or rides happening today
            if (rideDate >= today) {
              ridesData.push({
                ...ride,
                driverName: `${personalInfo.firstName || ''} ${personalInfo.lastName || ''}`,
                contact: personalInfo.phoneNumber || '',
                dayOfWeek: rideDate.toLocaleDateString('en-US', { weekday: 'long' }),
                formattedDate: rideDate.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }),
                rideId: rideId, // Store ride ID for deletion
                userId: childSnapshot.key, // Store user ID for permission check
              });
            }
          });
        }
      });

      // Sort rides by date
      ridesData.sort((a, b) => new Date(a.date) - new Date(b.date));
      setRides(ridesData);
    });
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logout successful');
        navigate('/'); // Redirect to the login page
      })
      .catch((error) => {
        console.error('Logout failed', error);
      });
  };

  const handleDelete = (rideId) => {
    const db = getDatabase();
    const rideRef = ref(db, `safeusers/${auth.currentUser.uid}/saferides/${rideId}`);

    if (window.confirm('Are you sure you want to delete this ride?')) {
      remove(rideRef)
        .then(() => {
          console.log('Ride deleted successfully');
        })
        .catch((error) => {
          console.error('Failed to delete ride:', error);
        });
    }
  };

  return (
    <div className="saferide-container">
      {isSafeUser && (
        <button className="button logout-button" onClick={handleLogout}>
          Logout
        </button>
      )}
      <h1>SafeRide Available Rides</h1>
      {rides.length > 0 ? (
        rides.map((ride, index) => (
          <div key={index} className="driver-info">
            <h3>Driver: {ride.driverName}</h3>
            <p>
              <strong>Date:</strong> {ride.formattedDate} ({ride.dayOfWeek} night)
            </p>
            <p>
              <strong>Availability:</strong> {ride.fromTime} to {ride.toTime}
            </p>
            <p>
              <strong>Seats available:</strong> {ride.seats}
            </p>
            <p>
              <strong>Contact:</strong> {ride.contact}
            </p>
            {ride.userId === auth.currentUser.uid && (
              <button onClick={() => handleDelete(ride.rideId)}>Delete</button>
            )}
          </div>
        ))
      ) : (
        <p>No available rides currently.</p>
      )}
    </div>
  );
}

export default SafeRide;
