import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database"; // Import set and ref for database operations
import './SignUp.css';

function SignUp({ setLoggedIn }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  // Function to save user information in Firebase Realtime Database
  const saveUserInfo = (userId, email, username, name, number) => {
    const db = getDatabase();
    set(ref(db, 'users/' + userId), {
      email,
      username,
      name,
      number
    }).then(() => {
      console.log("User information saved successfully.");
      setLoggedIn(true); // Log the user in after successful sign up and data saving
      // Redirect to profile or another page as needed
    }).catch((error) => {
      setError(error.message); // Set errors if saving to database fails
    });
  };

  const signUpUser = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    // Additional validation for other fields can be implemented here

    try {
      const userCredential = await createUserWithEmailAndPassword(getAuth(), email, password);
      console.log("Account created successfully", userCredential.user);
      // Save additional user info in Firebase Realtime Database
      saveUserInfo(userCredential.user.uid, email, username, name, number);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="sign-up-container">
      <form onSubmit={signUpUser} className="sign-up-form">
        <h2>Sign Up</h2>
        {error && <p className="error">{error}</p>}
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="tel" placeholder="Phone Number" value={number} onChange={(e) => setNumber(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default SignUp;
