import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, set, push } from 'firebase/database'; // Import onValue here
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Request.css';

function BookingModal({ isOpen, onClose, onBook, availableSeats, contactNumber }) {
  const [seatsToBook, setSeatsToBook] = useState('');

  const handleBook = () => {
    const seats = parseInt(seatsToBook, 10);
    if (seats > 0 && seats <= availableSeats) {
      onBook(seats);
      alert(`Ride Fulfilled. Contact number: ${contactNumber}`);
      onClose();
    } else {
      alert('Please enter a valid number of seats.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Book Seats</h2>
        <p>Available Seats: {availableSeats}</p>
        <input
          type="number"
          value={seatsToBook}
          onChange={(e) => setSeatsToBook(e.target.value)}
          placeholder="Number of seats"
        />
        <button onClick={handleBook}>Book</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

function Request() {
  const [rides, setRides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [currentRideForBooking, setCurrentRideForBooking] = useState(null);
  const navigate = useNavigate();
  const db = getDatabase();

  useEffect(() => {
    fetchRides();
  }, [db]);

  const fetchRides = () => {
    const ridesRef = ref(db, 'rideRequest');
    onValue(ridesRef, (snapshot) => {
      let ridesData = [];
      snapshot.forEach((childSnapshot) => {
        const ride = childSnapshot.val();
        const rideDate = new Date(ride.date);
        if (rideDate >= new Date()) {
          ridesData.push({ id: childSnapshot.key, ...ride });
        }
      });
  
      Promise.all(ridesData.map(ride => {
        return new Promise((resolve) => {
          if (!ride.userID) {
            console.error("Missing userID for ride:", ride);
            resolve(null);
          } else {
            const userRef = ref(db, `users/${ride.userID}`);
            onValue(userRef, (userSnapshot) => {
              if (userSnapshot.exists()) {
                const user = userSnapshot.val();
                ride.userUsername = user.username;
                ride.userContact = user.number;
                resolve(ride);
              } else {
                console.error("User snapshot does not exist for ride:", ride);
                resolve(null);
              }
            }, { onlyOnce: true });
          }
        });
      })).then(ridesWithUserInfo => {
        // Filter out any null values
        const validRides = ridesWithUserInfo.filter(ride => ride !== null);
        setRides(validRides);
        setIsLoading(false);
      });
    });
  };
  

  const openBookingModal = (ride) => {
    setCurrentRideForBooking(ride);
    setBookingModalOpen(true);
  };

  const bookSeats = (numberOfSeats) => {
    if (!currentRideForBooking || numberOfSeats <= 0) return;

    const updatedSeats = currentRideForBooking.seats - numberOfSeats;
    const rideRef = ref(db, `rideRequest/${currentRideForBooking.id}`);
    update(rideRef, { seats: updatedSeats })
      .then(() => {
        recordBooking(currentRideForBooking.id, numberOfSeats, currentRideForBooking);
        alert(`Fulfillment Confirmed! Contact number: ${currentRideForBooking.userContact}`);
        fetchRides();
      })
      .catch(error => {
        console.error('Error updating seats:', error);
        alert('Failed to fulfil seats.');
      });

    setBookingModalOpen(false);
  };

  const recordBooking = (rideId, numberOfSeats, rideInfo) => {
    const bookingRef = ref(getDatabase(), 'bookings');
    const newBookingRef = push(bookingRef);
    const bookerUserID = getAuth().currentUser?.uid;
    const providerUserID = rideInfo.userID;
    const bookingType = "request";

    set(newBookingRef, {
      rideID: rideId,
      bookerUserID: bookerUserID,
      providerUserID: providerUserID,
      type: bookingType,
      seatsBooked: numberOfSeats,
      dateBooked: new Date().toISOString()
    })
    .then(() => {
      console.log("Fulfilment recorded successfully.");
    })
    .catch((error) => {
      console.error("Error recording:", error);
    });
  };

  if (isLoading) {
    return <div className="loading-container">Loading rides...</div>;
  }

  return (
    <div className="rides-container">
      <button onClick={() => navigate('/AddRequest')} className="add-ride-button">Add Request</button>
      {rides.map(ride => (
        <div key={ride.id} className="ride">
          <h3>From: {ride.fromLocation} To: {ride.toLocation}</h3>
          <p>Date: {new Date(ride.date).toLocaleDateString()} Time: {ride.time}</p>
          <p>Seats available: {ride.seats}, Donation requested: {ride.donationRequested}</p>
          <p>Posted by: {ride.userUsername}</p>
          
          <p>Additional Info: {ride.additionalInfo}</p>
          {ride.seats > 0 && (
            <button onClick={() => openBookingModal(ride)} className="book-seat-button">Fulfill Seats</button>
          )}
        </div>
      ))}

      {bookingModalOpen && currentRideForBooking && (
        <BookingModal
          isOpen={bookingModalOpen}
          onClose={() => setBookingModalOpen(false)}
          onBook={bookSeats}
          availableSeats={currentRideForBooking.seats}
          //contactNumber={currentRideForBooking.userContact}
        />
      )}
    </div>
  );
}

export default Request;
