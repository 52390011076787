import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

function Settings() {
  const navigate = useNavigate();

  // Handle user logout
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/'); // Navigate to login page or wherever you see fit
    }).catch((error) => {
      // An error happened.
      console.error('Logout Error:', error);
    });
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <button onClick={handleLogout}>Log Out</button> {/* Logout button */}
    </div>
  );
}

export default Settings;
